import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  get_token (data) {
    return Vue.http.post(API_BASE_URL + 'v1/get_token', data);
  },
  login () {
    return Vue.http.post(API_BASE_URL + 'v1/login');
  },
  layer_wdmr_list (query, data) {
    return Vue.http.post(API_BASE_URL + 'v1/layer/wdmr/list?' + query, data);
  },
  update_layer_type (query) {
    return Vue.http.post(API_BASE_URL + 'v1/layer/update?' + query);
  },
  layer_wdmr_save_by_wdmr_id_list (query, data) {
    return Vue.http.post(API_BASE_URL + 'v1/layer/wdmr/save/by_wdmr_id_list?' + query, data);
  },
  layer_wdmr_trash (query) {
    return Vue.http.post(API_BASE_URL + 'v1/layer/wdmr/trash?' + query);
  },
  application_save (query, data) {
    return Vue.http.post(API_BASE_URL + 'v1/application/save?' + query, data);
  },
  ecosystem_save (query, data) {
    return Vue.http.post(API_BASE_URL + 'v1/ecosystem/save?' + query, data);
  },
  application_branch_merge (query, data) {
    return Vue.http.post(API_BASE_URL + 'v1/application/branch/merge?' + query, data);
  },
  application_branch_merge_multi (data) {
    return Vue.http.post(API_BASE_URL + 'v1/application/branch/merge/multi', data);
  },
  application_project_list (query) {
    return Vue.http.post(API_BASE_URL + 'v1/application/project/list?' + query);
  },
  application_project_data (query) {
    return Vue.http.post(API_BASE_URL + 'v1/application/project/data?' + query);
  },
  application_project_branch_list (query) {
    return Vue.http.post(API_BASE_URL + 'v1/application/project/branch/list?' + query);
  },
  application_project_branch_data (query) {
    return Vue.http.post(API_BASE_URL + 'v1/application/project/branch/data?' + query);
  },
  application_project_config_list (query) {
    return Vue.http.post(API_BASE_URL + 'v1/application/project/config/list?' + query);
  },
  application_project_config_data (query) {
    return Vue.http.post(API_BASE_URL + 'v1/application/project/config/data?' + query);
  },
  application_project_friend_list (query) {
    return Vue.http.post(API_BASE_URL + 'v1/application/project/friend/list?' + query);
  },
  application_project_friend_data (query) {
    return Vue.http.post(API_BASE_URL + 'v1/application/project/friend/data?' + query);
  },
  application_project_route_list (query) {
    return Vue.http.post(API_BASE_URL + 'v1/application/project/route/list?' + query);
  },
  application_project_route_data (query) {
    return Vue.http.post(API_BASE_URL + 'v1/application/project/route/data?' + query);
  },
  application_project_codepage_list (query) {
    return Vue.http.post(API_BASE_URL + 'v1/application/project/codepage/list?' + query);
  },
  application_project_codepage_data (query) {
    return Vue.http.post(API_BASE_URL + 'v1/application/project/codepage/data?' + query);
  },
  application_project_statuscode_list (query) {
    return Vue.http.post(API_BASE_URL + 'v1/application/project/statuscode/list?' + query);
  },
  application_project_statuscode_data (query) {
    return Vue.http.post(API_BASE_URL + 'v1/application/project/statuscode/data?' + query);
  },
  application_project_id_list (query) {
    return Vue.http.post(API_BASE_URL + 'v1/application/project/id/list?' + query);
  },
  application_project_id_data (query) {
    return Vue.http.post(API_BASE_URL + 'v1/application/project/id/data?' + query);
  },
  application_project_location_list (query) {
    return Vue.http.post(API_BASE_URL + 'v1/application/project/location/list?' + query);
  },
  application_project_location_data (query) {
    return Vue.http.post(API_BASE_URL + 'v1/application/project/location/data?' + query);
  },
  application_project_requirement_list (query) {
    return Vue.http.post(API_BASE_URL + 'v1/application/project/requirement/list?' + query);
  },
  application_project_requirement_data (query) {
    return Vue.http.post(API_BASE_URL + 'v1/application/project/requirement/data?' + query);
  },
  application_project_version_list (query) {
    return Vue.http.post(API_BASE_URL + 'v1/application/project/version/list?' + query);
  },
  application_project_version_data (query) {
    return Vue.http.post(API_BASE_URL + 'v1/application/project/version/data?' + query);
  },
  ecosystem_application_server_add (query) {
    return Vue.http.post(API_BASE_URL + 'v1/ecosystem/server/application/add?' + query);
  },
  ecosystem_list () {
    return Vue.http.post(API_BASE_URL + 'v1/ecosystem/list');
  },
  ecosystem_data (query) {
    return Vue.http.post(API_BASE_URL + 'v1/ecosystem/data?' + query);
  },
  get_ecosystem_server_application_database_connection_list (query) {
    return Vue.http.post(API_BASE_URL + 'v1/ecosystem/server/application/database/connection/list?' + query);
  },
  ecosystem_server_application_database_connection_change_apply (query, data) {
    return Vue.http.post(API_BASE_URL + 'v1/ecosystem/server/application/database/connection/change/apply?' + query, data);
  },
  ecosystem_server_application_branch_friend_update (query) {
    return Vue.http.post(API_BASE_URL + 'v1/ecosystem/server/application/branch/friends/update?' + query);
  }
};
