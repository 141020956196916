import Vue from 'vue';
import { API_WMANAGER_URL } from '@/config';
export default {
  save_this_wdmr_to_layer_by_wdmr_id_list(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/save/by_wdmr_id_list?' + query, data);
  },
  get_wdmr_list_by_wdmr_id(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/list/by_wdmr_id?' + query, data);
  },
  get_wdmr_list_and_layer_wdm_by_wdmr_id(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/list/and_layer_wdm/by_wdmr_id?' + query, data);
  },
  get_token(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/oauth/token?' + query, data);
  },
  check_token(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/oauth/token/check?' + query);
  },
  get_anatomy_model() {
    return Vue.http.get(API_WMANAGER_URL + 'v1/get_anatomy_model');
  }
};
